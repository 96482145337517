<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ tag.name_hy }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.tags.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter a tag in armenian"  label="Tag in armenian"
                                          v-model="tag.name_hy"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter a tag in russian" label="Tag in russian"
                                          v-model="tag.name_ru"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter a tag in english" label="Tag in english"
                                          v-model="tag.name_en"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter a slug in armenian"  label="Slug in armenian"
                                          v-model="tag.slug_hy" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter a slug in russian" label="Slug in russian"
                                          v-model="tag.slug_ru" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Please enter slug in english" label="Slug in english"
                                          v-model="tag.slug_en" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveTag">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Tag from "../../../models/Tag";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

export default {
    name: "tags-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            tag: {},
            formValid: true,
            nameRules: [v => !!v || 'Name is required'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSaveTag() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const tag = new Tag(this.tag);
                this.tag = await tag.save();
                await this.$router.push({name: 'admin.tags.show', params: {id: this.tag.id}}).catch(()=>{});
                this.snackbar = true;
            }
        }
    },
    async mounted() {
        const tagId = this.$route.params.id
        if (tagId) {
            this.tag = await Tag.find(tagId)
        }
    },
}
</script>

<style scoped>

</style>
